const uploadFileToZendesk = async (file) => {
    let attachment = await fetch(`https://selvbyggerplus.zendesk.com/api/v2/uploads.json?filename=${ file.name }`, {
        method: 'POST',
        headers: { 'Content-type': 'application/binary' },
        body: file,
    });

    attachment = await attachment.json();

    return attachment.upload;
};

export default uploadFileToZendesk;
